.food-ul-link:after{
    background-image: none;
}
.product-item .prod-info h4.combo-pro-title{
    text-align: right;
}
.product-item .prod-info  h4.combo-pro-price{
    margin-top: 30px;
}
.product-combo-item .prod-info .checkmark{
    background-color: #ccc;
}